<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>
          Client ID {{ $route.params.uid }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form>
          <v-text-field
            v-model="formData.auth.displayName"
            label="Display name"
            required
          ></v-text-field>
          <v-switch
            v-model="formData.auth.disabled"
            label="Disabled"
          />
          <v-text-field
            v-model="formData.auth.email"
            label="Email"
          ></v-text-field>
          <v-text-field
            v-model="formData.auth.phone"
            label="Phone"
            required
          ></v-text-field>
          <v-btn
            color="primary"
            @click="updateUser"
            :loading="saving"
          > SAVE
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-dialog
          v-model="dialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              :loading="resetLoading"
            >
              RESET PASSWORD
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5">
              CONFIRM ACTION
            </v-card-title>
            <v-card-text>Save user data and send email {{ formData.auth.email }}?</v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="dialog = false"
              >
                CANCEL
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="confirmReset"
              >
                CONFIRM
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getUserInfo, sendPasswordResetEmail, updateUserInfo } from '@/services/firebase';

export default {
  name: 'UserEdit',
  data() {
    return {
      dialog: false,
      saving: false,
      resetLoading: false,
      formData: {
        auth: {
          disabled: undefined,
          displayName: undefined,
          email: undefined,
          phone: undefined,
        },
        role: 'client',
      },
    };
  },
  methods: {
    confirmReset() {
      this.dialog = false;
      this.resetLoading = true;
      sendPasswordResetEmail(this.formData.auth.email)
        .then(() => {
          this.resetLoading = false;
        })
        .catch((e) => {
          console.error('Reset password error', e);
        })
        .finally(() => {
          this.resetLoading = false;
        });
    },
    updateUser() {
      this.saving = true;

      updateUserInfo(this.$route.params.uid, this.formData)
        .then(() => {
          this.$router.push({ name: 'UsersIndex' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
  mounted() {
    getUserInfo(this.$route.params.uid)
      .then((response) => {
        this.userInfo = response.data();
        this.formData.auth.disabled = response.data().auth.disabled;
        this.formData.auth.displayName = response.data().auth.displayName;
        this.formData.auth.email = response.data().auth.email;
        this.formData.auth.phone = response.data().auth.phone;
      });
  },
};
</script>

<style scoped>

</style>
