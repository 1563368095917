var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', [_c('h3', [_vm._v(" Client ID " + _vm._s(_vm.$route.params.uid) + " ")])])], 1), _c('v-row', [_c('v-col', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "label": "Display name",
      "required": ""
    },
    model: {
      value: _vm.formData.auth.displayName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "displayName", $$v);
      },
      expression: "formData.auth.displayName"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "Disabled"
    },
    model: {
      value: _vm.formData.auth.disabled,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "disabled", $$v);
      },
      expression: "formData.auth.disabled"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.formData.auth.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "email", $$v);
      },
      expression: "formData.auth.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Phone",
      "required": ""
    },
    model: {
      value: _vm.formData.auth.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "phone", $$v);
      },
      expression: "formData.auth.phone"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.updateUser
    }
  }, [_vm._v(" SAVE ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-dialog', {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "loading": _vm.resetLoading
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" RESET PASSWORD ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" CONFIRM ACTION ")]), _c('v-card-text', [_vm._v("Save user data and send email " + _vm._s(_vm.formData.auth.email) + "?")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" CANCEL ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.confirmReset
    }
  }, [_vm._v(" CONFIRM ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }